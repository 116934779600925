
import {} from "element-ui";
import { Component, Prop, Ref, Vue, Watch, Emit } from "vue-property-decorator";
import { Concept } from "@/models";
import { translations } from '@/mixins';
import Translations from '../../../Translations.vue';
import { mapGetters } from "vuex";

interface OpeningHoursDataObject {
  day: string;
  open: string;
  close: string;
}

const ConceptsSupportedOrderTypesProps = Vue.extend({
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
});

@Component({
  components: {
    Translations,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale'
    })
  },
  mixins: [translations],
})
export default class ConceptsSupportedOrderTypes extends ConceptsSupportedOrderTypesProps {
  translate!: Function;
  validFeedbackForm: Boolean = true;

  checkValidUrl(url: string = '') {
    
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    
    setTimeout(() => {
        /* @ts-ignore */
        if (!url.target._value) {
          this.validFeedbackForm = true;
        } else {
          /* @ts-ignore */
          this.validFeedbackForm = !!pattern.test(url.target._value);
        }
      /* @ts-ignore */
      console.log('checkValidUrl', url.target._value);
    }, 50);
  }
}
